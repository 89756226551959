export enum Sentiment {
    Frustration = "hasFrustration",
    Gratitude = "hasGratitude",
    Praise = "hasPraise",
}

export type ISentimentIndicators = {
    [key in Sentiment]: boolean;
};

export const sentimentLabels: Record<Sentiment, string> = {
    [Sentiment.Frustration]: "Frustration",
    [Sentiment.Gratitude]: "Gratitude",
    [Sentiment.Praise]: "Praise",
};

export const sentimentScoreLabels: Record<Sentiment, string> = {
    [Sentiment.Frustration]: "frustrationScore",
    [Sentiment.Gratitude]: "gratitudeScore",
    [Sentiment.Praise]: "praiseScore",
};
