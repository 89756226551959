import {
    IBooleanFormComponent,
    IDateFormComponent,
    IDateTimeFormComponent,
    IFormComponent,
    INumericFormComponent,
    IOutboundAttachment,
    IRatingFormComponent,
    ISelectFormComponent,
    ISourcedSelectFormComponent,
    ITextFormComponent,
    ITimeFormComponent,
    ITimestampFormComponent,
    ITranslations,
    VariableType,
} from "@edgetier/types";
import { IInteractionSearchItem } from "components/interaction-search/interaction-search.types";
import ActionStatus from "constants/action-status";
import ActionType from "constants/action-type";
import AgentActionType from "constants/agent-action-type";
import CallEventType from "constants/call-event-type";
import ConversationType from "constants/conversation-type";
import InteractionType from "constants/interaction-type";
import LogicType from "constants/logic-type";
import { IDepartment } from "types-for/departments/departments.types";
import { IRole } from "types-for/permissions/permissions.types";

import { IBackendChat } from "./modules/chat/chat.types";
import { IOutboundEmail, IQueryEmail } from "./modules/email/email.types";
import { ISkill } from "./modules/setup/setup.types";

export enum UserState {
    Offline = 1,
    Unavailable = 2,
    Online = 3,
}

export interface IUser {
    readonly acceptTranslations: boolean;
    readonly email: string;
    readonly firstName: string;
    readonly languages: IUserLanguage[];
    readonly nativeLanguage: string | null;
    readonly nativeLanguageId: number | null;
    readonly referenceCode: string | null;
    readonly role: IRole["role"];
    readonly roleId: IRole["roleId"];
    readonly skills: IAssignedSkill[];
    readonly isProactiveChatEnabled: boolean;
    readonly surname: string;
    readonly nickname?: string | null;
    readonly userId: number;
    readonly departmentRestricted: boolean;
    readonly departments: IDepartment[];
    readonly maximumConcurrentChats: number | null;
    readonly userState: string;
    readonly userStateId: UserState | number;
    readonly userStateUpdateDateTime: string;
}

export interface IUserState {
    readonly userStateId: UserState | number;
    readonly userState: string;
    readonly isCallEnabled: boolean;
    readonly isChatEnabled: boolean;
    readonly isEmailEnabled: boolean;
    readonly isUserEditable: boolean;
    readonly isProactiveChatEnabled: boolean;
}

export interface ICount<Group> {
    readonly count: number;
    readonly group: Group;
}

export interface IUsersCount<Group> extends ICount<Group> {}

export interface IInteractionsCount<Group> extends ICount<Group> {}

export interface IChatsCount<Group> extends ICount<Group> {}
export interface IEmailAccountsCount<Group> extends ICount<Group> {}

export interface IUserStateRequestParameters {
    readonly isProactiveChatEnabled?: boolean;
    readonly isUserEditable?: boolean;
    readonly limit?: number;
}

export interface IUserLanguage {
    readonly interactionTypeId: InteractionType;
    readonly language: string;
    readonly languageId: number;
    readonly primaryLanguage: boolean;
}

export interface IAssignedSkill {
    readonly interactionTypeId: InteractionType;
    readonly primarySkill: boolean;
    readonly skill: string;
    readonly skillId: number;
}

// The backend returns blank agent actions if new ones need to be created on the frontend. For example, agents can
// create blank emails. These required some minimum information described in this array.
export interface ITemplateAction {
    readonly data: IAction["data"];
    readonly type: AgentActionType;
}

export interface IParagraph {
    readonly enabled: boolean;
    readonly order: number;
    readonly paragraphType: number;
    readonly text: string;
    readonly title: string;
}

export interface IRouteData {
    readonly roleId: IRole["roleId"] | IRole["roleId"][];
}

export interface IQueryTextTemplateVariable {
    readonly name: string;
    readonly value: string | number | null;
}

export interface IBackendExternalInteraction {
    readonly body: string;
    readonly dateTime: string;
    readonly details: IDetailsSection[];
    readonly externalInteractionId: string;
    readonly label: string;
    readonly title: string;
}

// Contact history from external CRMs or databases.
export interface IExternalInteraction extends Omit<IBackendExternalInteraction, "dateTime"> {
    readonly dateTime: Date;
}

// Information common to both email and chat.
export interface IBaseInteraction {
    readonly bookingDetails?: IBookingDetails | null;
    readonly conversationKey: string;
    readonly interactionDetailId: number;
    readonly languageId: number;
    readonly settings: ISettings;
    readonly skills: ISkill[];
    readonly textTemplateVariables?: IQueryTextTemplateVariable[];
}

export interface ICallEvent {
    readonly callEventId: number;
    readonly callEventType: string;
    readonly callEventTypeId: CallEventType;
    readonly dateTime: string;
    readonly formSubmissionId: number;
    readonly isFromCallback: boolean;
    readonly userId: number;
}

export interface ICallInteraction extends IBaseInteraction {
    readonly brandId: number;
    readonly callId: number;
    readonly completed: boolean;
    readonly conversationId: number;
    readonly endDateTime: string;
    readonly events: ICallEvent[];
    readonly inbound: boolean;
    readonly initialDateTime: string;
    readonly lastActiveUserId: number;
    readonly phoneNumber: number;
    readonly phoneNumberId: number;
}

export type IInteraction = IBackendChat | IQueryEmail | IOutboundEmail | ICallInteraction;

export interface IConversation {
    readonly conversationId: number;
    readonly conversationKey: string;
    readonly conversationType: string;
    readonly conversationTypeId: ConversationType;
    readonly interactionTypeId: number;
    readonly interactionType: string;
    readonly queryId: number;
    readonly surveyFormSubmissionId: number | null;
    readonly surveySent: boolean;
}

// Details of a customer URL used in chat. The backend parses query parameters and sends them as an array. This appears
// to agents on the chat screen and to both administrators and agents on the interaction search screen.
export interface ICustomerUrl {
    readonly dateTime: Date;
    readonly details: ICustomerUrlDetail[];
    readonly isChatStartUrl: boolean;
    readonly pageTitle: string | null;
    readonly url: string;
}

// Reporting axes are used for defining visualisation queries
export type IReportAxisOption = {
    readonly reportAxisId: number;
    readonly reportAxis: string;
};

// Report groups
export type IReportGroup = {
    readonly reportGroupId: number;
    readonly reportGroup: string;
};

// Report functions are API options used to define an operation on a metric in a visualisation definition, i.e. COUNT, MEAN etc.
export type IReportFunctionOption = {
    readonly reportFunctionId: number;
    readonly reportFunction: string;
};

// Metrics are API options for deriving data for visualisations or reports, they include a list of compatible function operations that can be applied on them.
export type IMetricOption = {
    readonly reportMetricId: number;
    readonly reportMetric: string;
    readonly reportFunctions: IReportFunctionOption[];
};

// Customer URLs are parsed by the backend. Each query parameter is broken down into an array of titles and values.
interface ICustomerUrlDetail {
    readonly title: string;
    readonly value: string | null;
}

interface IFormSubmissionFieldTemplate<Field extends IFormComponent, Value> {
    readonly formField: Field;
    readonly formFieldTypeId: Field["formFieldTypeId"];
    readonly formFieldTranslation: string;
    readonly formSubmissionFieldId: number;
    readonly formSubmissionId: number;
    readonly value: Value | null;
}

export type IFormSubmissionBooleanField = IFormSubmissionFieldTemplate<IBooleanFormComponent, boolean>;
export type IFormSubmissionTextField = IFormSubmissionFieldTemplate<ITextFormComponent, string | ITranslations>;
export type IFormSubmissionTimestampField = IFormSubmissionFieldTemplate<ITimestampFormComponent, string>;
export type IFormSubmissionDateField = IFormSubmissionFieldTemplate<IDateFormComponent, string>;
export type IFormSubmissionTimeField = IFormSubmissionFieldTemplate<ITimeFormComponent, string>;
export type IFormSubmissionDateTimeField = IFormSubmissionFieldTemplate<IDateTimeFormComponent, string>;
export type IFormSubmissionNumericField = IFormSubmissionFieldTemplate<INumericFormComponent, number>;
export type IFormSubmissionRatingField = IFormSubmissionFieldTemplate<IRatingFormComponent, number>;
export type IFormSubmissionSelectField = IFormSubmissionFieldTemplate<ISelectFormComponent, string[]>;
export type IFormSubmissionSourcedSelectField = IFormSubmissionFieldTemplate<ISourcedSelectFormComponent, string[]>;

// Values that the customer provides on the chat form.
export type IFormSubmissionField =
    | IFormSubmissionBooleanField
    | IFormSubmissionNumericField
    | IFormSubmissionRatingField
    | IFormSubmissionSelectField
    | IFormSubmissionSourcedSelectField
    | IFormSubmissionTextField
    | IFormSubmissionTimestampField
    | IFormSubmissionTimeField
    | IFormSubmissionDateTimeField
    | IFormSubmissionDateField;

// Agents can defer an email to be answered later.
export interface IDeferredQuery {
    readonly deferredUntil: Date;
    readonly emailId: number;
    readonly note: string;
    readonly senderEmail: string;
}

// Details included in every action regardless of the type.
interface IBaseAction<P = {}, F = P> {
    readonly data: P;
    readonly enabled: boolean;
    readonly final: F;
    readonly immediate: boolean;
    readonly order: number;
    readonly proposed: P;
    readonly title: string;
    readonly type: AgentActionType;
}

export interface IActionType {
    readonly actionTypeId: ActionType;
    readonly actionType: string;
}

export interface IGenericAction {
    readonly actionId: number;
    readonly actionStatusId: ActionStatus;
    readonly actionType: string;
    readonly actionTypeId: ActionType;
    readonly completed: boolean;
    readonly completedDate: Date | null;
    readonly createDateTime: Date;
    readonly lastTried: Date | null;
    readonly tryCount: number;
}

export interface IOutboundEmailAction
    extends Pick<
        IOutboundEmail,
        | "attachments"
        | "bodyHtml"
        | "bodyText"
        | "brandId"
        | "conversationId"
        | "conversationKey"
        | "fromEmailAddress"
        | "interactionDetailId"
        | "languageId"
        | "outboundEmailActionId"
        | "subject"
        | "surveyFormSubmissionId"
        | "translatedToLanguageId"
    > {
    readonly brandEmailAddressId: number | null;
    readonly emailAddress: string;
}

export interface ISendEmailAction
    extends IBaseAction<
        {
            readonly agentLanguageId: number;
            readonly attachments: IOutboundAttachment[];
            readonly body: IParagraph[];
            readonly brandId: number | null;
            readonly brandEmailAddressId: number | null;
            readonly canTranslate: boolean;
            readonly languageId: number;
            readonly queryId: number;
            readonly recipient: string[] | null;
            readonly subject: string;
            readonly type: AgentActionType.SendEmail;
        },
        {
            readonly agentLanguageId: number;
            readonly attachments: IOutboundAttachment[];
            readonly body: ITranslations;
            readonly brandId: number | null;
            readonly brandEmailAddressId: number | null;
            readonly canTranslate: boolean;
            readonly languageId: number;
            readonly recipient: string[] | null;
            readonly queryId: number;
            readonly subject: ITranslations;
            readonly thread: string | null;
            readonly type: AgentActionType.SendEmail;
        }
    > {
    readonly type: AgentActionType.SendEmail;
}

export interface INoActionRequiredAction extends IBaseAction {
    readonly type: AgentActionType.NoActionRequired;
}

export type IAction = INoActionRequiredAction | ISendEmailAction;

// Field of various details provided by the backend about a customer or email etc.
export interface IDetailsSection {
    readonly fields: IDetailsField[];
    readonly title: string;
}

export interface IDetailsArrayFieldSection extends IDetailsSection {
    readonly isOpen: boolean;
}

export interface IDetailsArrayField extends IDetailsFieldTemplate<string, { sections: IDetailsArrayFieldSection[] }> {
    readonly variableTypeId: VariableType.Array;
}

export interface IDetailsBooleanField extends IDetailsFieldTemplate<boolean> {
    readonly variableTypeId: VariableType.Boolean;
}

export interface IDetailsDateField extends IDetailsFieldTemplate<string> {
    readonly variableTypeId: VariableType.Date;
}

export interface IDetailsLinkField extends IDetailsFieldTemplate<string, { url: string }> {
    readonly variableTypeId: VariableType.Link;
}

export interface IDetailsNumericField extends IDetailsFieldTemplate<number> {
    readonly variableTypeId: VariableType.Numeric;
}

export interface IDetailsStringField extends IDetailsFieldTemplate<string> {
    readonly variableTypeId: VariableType.String;
}

export interface IDetailsTimeField extends IDetailsFieldTemplate<string> {
    readonly variableTypeId: VariableType.Time;
}

export type IDetailsField =
    | IDetailsArrayField
    | IDetailsBooleanField
    | IDetailsDateField
    | IDetailsLinkField
    | IDetailsNumericField
    | IDetailsStringField
    | IDetailsTimeField;

// Customer details.
interface IDetailsFieldTemplate<V, P = null> {
    readonly properties: Readonly<P>;
    readonly title: string;
    readonly value: V;
    readonly warning: string | null;
}

// Customer details section. Each has a title and multiple title/value pairs.
export interface IBookingDetailSection {
    readonly fields: IDetailsField[];
    readonly title: string;
}

// Links to various external CRMs etc. that can be provided by the backend.
export interface IBookingDetailButton {
    readonly label: string;
    readonly link: string;
    readonly copyOnClick: boolean;
}

// CRM and various other customer details provided by the backend.
export interface IBookingDetails {
    readonly buttons: IBookingDetailButton[];
    readonly sections: IBookingDetailSection[];
}

// Application settings.
export interface ISettings {
    readonly agentChatAttachmentsEnabled: boolean;
    readonly agentInvitationTimeoutSeconds: number;
    readonly agentOpeningProposedMessagesEnabled: boolean;
    readonly agentQualityFormFieldId: number;
    readonly agentQualitySurveysCount: number;
    readonly assignEmailResponses: boolean;
    readonly automaticChatWrapUp: boolean;
    readonly automaticChatWrapUpSeconds: number;
    readonly automaticallyAcceptInvites: boolean;
    readonly restrictFromEmailAddressBasedOnEmailSkill: boolean;
    readonly blockedQueryStateEnabled: boolean;
    readonly bookingIdDescription: string;
    readonly bookingIdName: string;
    readonly brandCustomDataLabel: string;
    readonly callFunctionalityEnabled: boolean;
    readonly callShortAbandonSeconds: number;
    readonly chatAnswerSlaSeconds: number;
    readonly chatDeflectionEnabled: boolean;
    readonly chatFunctionalityEnabled: boolean;
    readonly chatPredictionsEnabled: boolean;
    readonly chatQueueLimit: number | null;
    readonly chatQueueLimitEnabled: boolean;
    readonly chatQueueLimitPerSetupEnabled: boolean;
    readonly chatShortAbandonSeconds: number;
    readonly chatSkillsEnabled: boolean;
    readonly chatSoundAlertCustomerLeavesChatEnabled: boolean;
    readonly chatSoundAlertEveryMessageEnabled: boolean;
    readonly chatSoundAlertNewChatEnabled: boolean;
    readonly chatSurveyMinimumAgentMessages: number;
    readonly chatSurveyMinimumDurationSeconds: number;
    readonly chatSurveyMinimumCustomerMessages: number;
    readonly customerChatAttachmentsEnabled: boolean;
    readonly customerChatAttachmentsLimit: number;
    readonly defaultHandledQueryStateId: number;
    readonly defaultMaximumConcurrentChats: number;
    readonly departmentRestrictedBrandsEnabled: boolean;
    readonly departmentRestrictedSetupsEnabled: boolean;
    readonly departmentRestrictedTransfersEnabled: boolean;
    readonly downloadTranscriptsEnabled: boolean;
    readonly emailAddressSkillAssignmentEnabled: boolean;
    readonly emailAllowedTimeMinutes: number;
    readonly emailAnswerSlaSeconds: number;
    readonly emailAttachmentLimitBytes: number;
    readonly emailFunctionalityEnabled: boolean;
    readonly emailPauseFunctionalityEnabled: boolean;
    readonly emailResponseAssignmentMinutes: number;
    readonly emailSkillsEnabled: boolean;
    readonly emailTranscriptsEnabled: boolean;
    readonly finalInactivityPromptSeconds: number;
    readonly finalMessageBirdInactivityPromptSeconds: number;
    readonly firstInactivityPromptSeconds: number;
    readonly firstMessageBirdInactivityPromptSeconds: number;
    readonly highQualityBrandsEnabled: boolean;
    readonly moveToSurveyOnCustomerEndChat: boolean;
    readonly offlineChatEnabled: boolean;
    readonly previousMessageBirdChatDetailsValidHours: number;
    readonly printTranscriptsEnabled: boolean;
    readonly proactiveAvailabilityDelaySeconds: number;
    readonly proactiveChatAgainDelayHours: number;
    readonly proactiveChatEnabled: boolean;
    readonly proactiveInvitationTimeoutSeconds: number;
    readonly proposedGreetingActivityTimeoutSeconds: number;
    readonly proposedTranslationGreetingTimeoutSeconds: number;
    readonly reservedPriorityChatCapacityPercentage: number;
    readonly secondInactivityPromptSeconds: number;
    readonly shortenedSurveyLinksEnabled: boolean;
    readonly stopChatInactivityTimerEnabled: boolean;
    readonly tinyUrlApiKey: string;
}

export interface IAgentOnChat {
    readonly firstName: string;
    readonly isAtCapacity: boolean;
    readonly languages: IUserLanguage[];
    readonly surname: string;
    readonly userId: number;
}

export interface IBackendNote {
    readonly dateTime: string;
    readonly interactionDetailId: number;
    readonly noteId: number;
    readonly note: string;
    readonly queryId: number;
    readonly userId: number;
}

export interface INote extends Omit<IBackendNote, "dateTime"> {
    readonly dateTime: Date;
}

export type IQueryHistoryItem = IExternalInteraction | IInteractionSearchItem | INote;

export interface IStartBlock {
    readonly conversationType: string;
    readonly blockId: number;
    readonly intent: string;
    readonly logicType: string;
    readonly logicTypeId: LogicType;
    readonly startBlock: string;
    readonly startBlockId: number;
    readonly topic: string;
}
