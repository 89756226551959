export enum Url {
    ActionTypes = "/action-types",
    Actions = "/actions",
    ActionsCounts = "/actions/counts",
    Action = "/action",
    ActionRetry = "/actions/:actionId/retries",
    AgentHandlingTime = "/reporting/average-handling-time",
    AgentInformation = "/agent-information",
    AgentsOnChat = "/agents-on-chat",
    Attachments = "/attachments",
    AuthMethods = "/auth-methods",
    AutomatableEmail = "/automatable-email",
    BanEmailAddresses = "/banned-email-addresses",
    BanIpAddresses = "/banned-ip-addresses",
    BookingDetails = "/booking-details",
    BrandEmailAddresses = "/brand-email-addresses",
    Brands = "/brands",
    Call = "/calls",
    ChangeLanguage = "/change-language",
    Chat = "/chats",
    ChatsLiveChats = "/chats/live-chats",
    ChatsLiveChatsCounts = "/chats/live-chats/counts",
    ChatLocation = "/chats/:chatId/location",
    ChatDevice = "/chats/:chatId/device",
    ChatBrowser = "/chats/:chatId/browser",
    ChatOperatingSystem = "/chats/:chatId/operating-system",
    ChatIPAddress = "/chats/:chatId/ip-address",
    ChatTranscript = "/transcripts",
    ChatWallboard = "/live-chat-reporting/chat-wallboard",
    ChatWidgetAppearances = "/chat-widget-appearances",
    ChatDomains = "/chat-domains",
    Configuration = "/chat-configuration",
    Conversations = "/conversations",
    ConversationsTranscript = "/conversations/:conversationId/transcripts",
    CustomerUrls = "/customer-urls",
    InteractionsAnalytics = "/interactions/analytics",
    InteractionsAnalyticsMetrics = "/interactions/analytics/metrics",
    EmailAccounts = "/email-accounts",
    EmailAccountsCounts = "/email-accounts/counts",
    EmailAccountsOAuth = "/email-accounts/oauth",
    EmailAccountsDevelopmentLoginUrl = "/email-accounts/oauth/fake-url",
    EmailAccountsEntity = "/email-accounts/:mailId",
    EmailAccountsPostValidation = "/email-accounts/:mailId/post-validation",
    EmailAccountsPreValidation = "/email-accounts/pre-validation",
    EmailAccountsTestEmail = "/email-accounts/:mailId/test-email",
    EmailRoutingRules = "/email-routing-rules",
    EmailSection = "/email-sections/:emailSectionId",
    EmailSections = "/email-sections",
    Departments = "/departments",
    Emails = "/emails",
    EmailsAssignments = "/emails/:emailId/assignments",
    EmailsDeferrals = "/deferrals",
    EmailsDeferred = "/emails/deferred",
    EmailsHandleQuery = "/emails/:emailId/handler",
    EmailsNext = "/emails/next",
    EmailsProposedActions = "/proposed-actions",
    ExternalInteractions = "/external-interactions",
    Fields = "/fields",
    Flow = "/logic/flow",
    FlowBlockEmailSections = "/logic/blocks/:blockId/email-sections",
    FlowBlockEmailTemplates = "/email-templates/:emailTemplateId",
    FormFields = "/form-fields",
    FormFieldsSearch = "/form-fields/:formFieldId/search",
    FormSubmissions = "/form-submissions",
    Forms = "/forms",
    Intents = "/intents",
    Interactions = "/interactions",
    InteractionsCounts = "/interactions/counts",
    InteractionMetricsDistributions = "/interaction-metrics/distribution",
    InteractionVariables = "/interactions/:interactionDetailId/variables",
    Languages = "/languages",
    LiveChatReportingQueueLength = "/live-chat-reporting/queue-length",
    LiveChatReportingProcessingCount = "/live-chat-reporting/live-processing-count",
    LiveChatReportingAgentsOnline = "/live-chat-reporting/agents-online",
    LiveChatReportingProcessedLastHour = "/live-chat-reporting/processed-last-hour",
    LiveChatReportingLongestWaitTime = "/live-chat-reporting/longest-wait-time",
    LiveChatReportingAverageWaitTime = "/live-chat-reporting/average-wait-time",
    LiveChatReportingAverageChatLength = "/live-chat-reporting/average-chat-length",
    LiveChatReportingAbandonedRate = "/live-chat-reporting/abandoned-rate",
    LiveEmailReportingEntitySummaries = "/live-email-reporting/entity-summaries",
    LiveEmailReportingQueueResponses = "/live-email-reporting/queue-responses",
    LiveEmailReportingQueueLength = "/live-email-reporting/queue-length",
    LiveEmailReportingQueueOutsideSla = "/live-email-reporting/queue-outside-sla",
    LiveEmailReportingQueueOldestEmail = "/live-email-reporting/queue-oldest-email",
    LiveEmailReportingProcessed = "/live-email-reporting/processed",
    LiveEmailReportingProcessingRate = "/live-email-reporting/processing-rate",
    LiveEmailReportingServiceLevelAgreement = "/live-email-reporting/service-level-agreement",
    LiveEmailReportingQueueTrend = "/live-email-reporting/queue-trend",
    LiveEmailReportingQueueMovement = "/live-email-reporting/queue-movement",
    LiveEmailReportingQueueDistribution = "/live-email-reporting/queue-distribution",
    MailsConfiguration = "/mails",
    ManualRoutingEmails = "/manual-routing-emails",
    MessageBirdChannels = "/message-bird-channels",
    Notes = "/notes",
    OfflineDeflection = "/offline-deflection",
    OutboundEmailAction = "/outbound-email-actions/:outboundEmailActionId",
    OutboundEmailActions = "/outbound-email-actions",
    Predictions = "/predictions",
    Permissions = "/permissions",
    Query = "/queries",
    QuerySearch = "/email-search",
    ReportAxes = "/report-axes",
    ReportFunctions = "/report-functions",
    ReportGroups = "/report-groups",
    ReportMetrics = "/report-metrics",
    ReportingCallWallBoard = "/reporting/call-wallboard",
    ReportingCustom = "/reporting/custom",
    ReportingHistoric = "/reporting-historic",
    ReportingHistoricInteractionsOverTime = "/reporting/interactions-over-time",
    ReportingHistoricLanguages = "./reporting/interactions-by-language",
    ReportingHistoricUsers = "./reporting/interactions-by-user",
    ReportingPublic = "/reporting-public",
    ReportingQueue = "/reporting-queue",
    Reports = "/reports",
    Role = "/roles/:roleId",
    Roles = "/roles",
    RolePermission = "/roles/:roleId/permissions/:permissionId",
    RolePermissions = "/roles/:roleId/permissions",
    SelectOptions = "/select-options",
    SelectSources = "/select-sources",
    Settings = "/settings",
    Setup = "/setup",
    Setups = "/setups",
    Skills = "/skills",
    SocketDetails = "/socket-details",
    StartBlocks = "/logic/start-blocks",
    SurveyForms = "/survey-forms",
    Templates = "/text-templates",
    TemplatesUsagesStatistics = "/text-templates/usages/statistics",
    TemplatesFeatured = "/text-templates/featured",
    TemplatesUsages = "/text-templates/:textTemplateId/usages",
    TextLocalisations = "/text-localisations",
    TextLocalisationTranslations = "/text-localisation-translations",
    TextTranslations = "/text-localisations/:textLocalisationId/translations",
    TextTemplateGroups = "/text-template-groups",
    TextTemplateVariables = "/text-template-variables",
    Themes = "/themes",
    Topics = "/topics",
    Translations = "/translations",
    UserSignOut = "/users/:userId/sign-outs",
    Users = "/users",
    UsersCounts = "/users/counts",
    UsersMe = "/users/me",
    UsersMetrics = "/users/metrics",
    UserStates = "/user-states",
    Variables = "/variables",
    ValidateToken = "/token",
    ValidateUser = "/authenticate",
    Visualisations = "/visualisations",
    WrapUpForms = "/wrap-up-forms",
    WebsiteSessions = "/website-sessions",
    WebsiteSessionInvitations = "/website-sessions/:websiteSessionId/invitations",
    WebsiteSessionsRejections = "/website-sessions/:websiteSessionId/rejections",
    WebsiteSessionStates = "/website-session-states",
    WebsiteSessionVariableStrings = "/website-session-variable-strings",
}

export default Url;
